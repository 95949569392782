import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Space, Dropdown, MenuProps, Modal, Tabs } from "antd";

import { SettingsDropdown } from "./SettingsDropdown";
import { AccountModalTab } from "./AccountModalTab";

const tabsItems = [
  {
    label: "Account",
    key: "Account",
  },
  {
    label: "Business profile",
    key: "Business profile",
  },
  {
    label: "Employees",
    key: "Employees",
  },
];

const allItems = [
  ...tabsItems,
  {
    label: "Log out",
    key: "Log out",
  },
];

export const SettingsModal = () => {
  const [activeTab, setActiveTab] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClick: MenuProps["onClick"] = ({ key }) => {
    // TODO(): based on key it has to make different stuff
    setActiveTab(key);
    showModal();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Settings"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Tabs
          animated={false}
          activeKey={activeTab}
          onChange={(activeKey) => setActiveTab(activeKey)}
          items={tabsItems}
        />
        {/* TODO(): add modal tabs */}
        {activeTab === "Account" && <AccountModalTab />}
        {activeTab === "Business profile" && <p>Business profile</p>}
        {activeTab === "Employees" && <p>Employees</p>}
      </Modal>
      <Space>
        <Dropdown menu={{ items: allItems, onClick }} trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <SettingsDropdown />
              <DownOutlined style={{ color: "000" }} />
            </Space>
          </a>
        </Dropdown>
      </Space>
    </>
  );
};
