import React from "react";
import { Table as AntTable, Progress } from "antd";
const { Column } = AntTable;

interface DataType {
  key: React.Key;
  cases: string;
  assigned: string;
  templates: string;
  progress: number;
  step: string;
  reactions: string;
}

const dataSource: DataType[] = [
  {
    key: "1",
    cases: "John Doe",
    assigned: "Peter Parker",
    templates: "Basic 10",
    progress: 60,
    step: "Making photos",
    reactions: "❤️",
  },
  {
    key: "1",
    cases: "Charlez Bukowsky",
    assigned: "Jim Jarmush",
    templates: "Super 666",
    progress: 10,
    step: "Drinking beer",
    reactions: "🤮",
  },
];

export const TableOfCases = () => {
  return (
    <AntTable dataSource={dataSource}>
      <Column title="Cases" dataIndex="cases" key="cases" />
      <Column title="Assigned" dataIndex="assigned" key="assigned" />
      <Column title="Templates" dataIndex="templates" key="templates" />
      <Column
        title="Progress"
        key="progress"
        render={(_: any, record: DataType) => (
          <Progress percent={record.progress} />
        )}
      />
      <Column title="Step" dataIndex="step" key="step" />
      <Column title="Reactions" dataIndex="reactions" key="reactions" />
    </AntTable>
  );
};
