import React from "react";
import { Handle, Position } from "reactflow";
import { Input, Flex, Card } from "antd";

export const Node = () => {
  return (
    <Flex className="root" vertical gap={6}>
      <Card size="small" style={{ width: 325 }}>
        <Input.TextArea
          placeholder="Title"
          variant="borderless"
          autoSize={{ minRows: 1 }}
        />
        <Input.TextArea
          variant="borderless"
          placeholder="Description"
          autoSize={{ minRows: 1 }}
        />
      </Card>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
    </Flex>
  );
};
