import React from "react";
import { Layout, Flex, Tabs } from "antd";
const { Header, Content } = Layout;
import { UnorderedListOutlined, FilterOutlined } from "@ant-design/icons";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { SettingsModal } from "./SettingsModal";

export const BasicLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Layout>
      <Header>
        <Flex
          // TODO(): background has to be full page
          style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.06)" }}
          justify="space-between"
          align="flex-end"
        >
          <p style={{ margin: 0, padding: 0 }}>Logo</p>
          {/* TODO(): has grey line, but it shouldn't */}
          {/* TODO(): maybe I need a Menu component here instead of Tabs */}
          <Tabs
            animated={false}
            activeKey={location.pathname}
            onChange={(activeKey) => navigate(activeKey)}
            items={[
              {
                link: "/cases",
                Icon: UnorderedListOutlined,
                title: "Cases",
              },
              {
                link: "/templates",
                Icon: FilterOutlined,
                title: "Templates",
              },
            ].map(({ link, Icon, title }) => {
              return {
                key: link,
                label: title,
                icon: <Icon />,
              };
            })}
          />
          {/* TODO(): should be centered */}
          <SettingsModal />
        </Flex>
      </Header>
      <Content style={{ padding: "20px 48px 0" }}>
        <Outlet />
      </Content>
    </Layout>
  );
};
