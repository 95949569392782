import React from "react";
import { Flex } from "antd";
import { TableToolbar } from "./TableToolbar";
import { TableOfCases } from "./TableOfCases";

export const CaseListPage = () => {
  return (
    <Flex vertical gap={10} style={{ marginTop: "10px" }}>
      <TableToolbar title="Add case" />
      <TableOfCases />
    </Flex>
  );
};
