import React from "react";
import { Button, Flex } from "antd";
import { PlusOutlined } from "@ant-design/icons";

interface TableToolbarProps {
  title: string;
  onClick?: () => void;
}

// TODO: I cannot use it everywhere. Maybe I should just add a title property and
// onClick here and everything will work
export const TableToolbar = (props: TableToolbarProps) => {
  const { title } = props;

  return (
    <Flex justify="flex-end">
      <Button type="primary" icon={<PlusOutlined />}>
        {title}
      </Button>
    </Flex>
  );
};
