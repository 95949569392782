import React from "react";
import { SettingsAvatar } from "./SettingsAvatar";

export const SettingsDropdown = () => {
  return (
    <SettingsAvatar
      avatarSrc="https://avatar.iran.liara.run/public"
      title="Alex O."
    />
  );
};
