import React from "react";
import { Avatar, Button, Input, Tabs } from "antd";
import {
  FilterOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";

export const AccountModalTab = () => (
  <>
    <Avatar
      src="https://avatar.iran.liara.run/public"
      size={98}
      icon={<UserOutlined />}
    />
    <Button>Replace</Button>
    <Button type="text">Delete</Button>
    <Input placeholder="name" />
    <Input placeholder="email" />
  </>
);
