import React from "react";
import { Table as AntTable, Progress } from "antd";
const { Column } = AntTable;

interface DataType {
  key: React.Key;
  template: string;
  assigned: number;
  closedCases: number;
}

const dataSource: DataType[] = [
  {
    key: "1",
    template: "Basic 10",
    assigned: 6,
    closedCases: 10,
  },
  {
    key: "1",
    template: "Super 666",
    assigned: 17,
    closedCases: 60,
  },
];

export const TableOfTemplates = () => {
  return (
    <AntTable dataSource={dataSource}>
      <Column title="Templates" dataIndex="template" key="template" />
      <Column title="Assigned" dataIndex="assigned" key="assigned" />
      <Column title="Closed cases" dataIndex="closedCases" key="closedCases" />
    </AntTable>
  );
};
