import React, { useMemo } from "react";
import ReactFlow, {
  Controls,
  NodeResizer,
  NodeToolbar,
  useEdgesState,
  useNodesState,
  useReactFlow,
  useViewport,
} from "reactflow";
import { Node } from "./Node";

const initialNodes = [
  {
    id: "1",
    type: "node",
    position: { x: 0, y: 0 },
    data: {},
  },
];

const initialEdges = [];

export const Diagram = () => {
  const nodeTypes = useMemo(() => ({ node: Node }), []);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const { screenToFlowPosition } = useReactFlow();
  const viewport = useViewport();
  let sourceNodeId = null;

  const handleConnectionStart = (event, params) => {
    const { nodeId } = params;
    sourceNodeId = nodeId;
    console.log("connection start");
    // debugger;
  };

  const handleConnectionEnd = (event) => {
    if (!sourceNodeId) {
      // error
      return;
    }

    console.log(viewport);
    const { x, y } = event;
    console.log("connection end");
    const newNodeId = String(Math.ceil(Math.random() * 10000000));
    // half of width
    const NODE_WIDTH = nodes[0].width ?? 0;
    const HALF_OF_NODE_WIDTH = NODE_WIDTH / 2;
    console.log(HALF_OF_NODE_WIDTH);
    const newNodePosition = screenToFlowPosition({ x: event.x, y: event.y });
    // debugger;
    const newEdgeId = String(Math.ceil(Math.random() * 10000000));
    const node = {
      id: newNodeId,
      type: "node",
      position: {
        x: newNodePosition.x - HALF_OF_NODE_WIDTH,
        y: newNodePosition.y,
      },
      data: { label: "New kid on the block" },
    };
    const edge = {
      id: newEdgeId,
      source: sourceNodeId,
      target: newNodeId,
      animated: true,
    };

    setNodes([...nodes, node]);
    setEdges([...edges, edge]);
    sourceNodeId = null;
    // debugger;
  };

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <ReactFlow
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onConnectStart={handleConnectionStart}
        onConnectEnd={handleConnectionEnd}
        // onEdgesChange={onEdgesChange}
        // onConnect={() => console.log('connected')}
        fitView
      >
        <Controls />
        <NodeToolbar />
        <NodeResizer />
      </ReactFlow>
    </div>
  );
};
