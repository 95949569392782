import React from "react";
import { ReactFlowProvider } from "reactflow";
import { App as AntdApp, ConfigProvider } from "antd";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "reactflow/dist/style.css";
import { Diagram } from "./Diagram";
import { CaseListPage } from "../pages/CaseListPage";
import { TemplateListPage } from "../pages/TemplateListPage";
// TODO(): what to do with this long names
import { BasicLayout } from "../pages/BasicLayout/ui/BasicLayout";

const router = createBrowserRouter([
  {
    path: "/cases",
    element: <BasicLayout />,
    children: [{ path: "/cases", element: <CaseListPage /> }],
  },
  {
    path: "/templates",
    element: <BasicLayout />,
    children: [{ path: "/templates", element: <TemplateListPage /> }],
  },
  {
    path: "/diagram",
    element: (
      <ReactFlowProvider>
        <Diagram />
      </ReactFlowProvider>
    ),
  },
]);

const theme = {
  token: {
    fontSize: 16,
    fontSizeHeading3: 24,
  },
  components: {
    Layout: {
      headerBg: "transparent",
      bodyBg: "#ffffff",
    },
    Tabs: { horizontalMargin: 0 },
  },
};

export const App = () => {
  return (
    <ConfigProvider theme={theme}>
      <AntdApp>
        <RouterProvider router={router} />
      </AntdApp>
    </ConfigProvider>
  );
};
