// TODO(): this one is duplicated (the same component in the Customer page)
import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Flex, Avatar, Typography } from "antd";

// TODO():move it to shared
type Url = string;

interface SettingsAvatarProps {
  avatarSrc: Url;
  title: string;
}

export const SettingsAvatar = (props: SettingsAvatarProps) => {
  const { avatarSrc, title } = props;

  return (
    <Flex gap={5} align="center">
      <Avatar src={avatarSrc} size={26} icon={<UserOutlined />} />
      <Typography>{title}</Typography>
    </Flex>
  );
};
