import React from "react";
import { Flex } from "antd";
import { TableToolbar } from "./TableToolbar";
import { TableOfTemplates } from "./TableOfTemplates";

export const TemplateListPage = () => {
  return (
    <Flex vertical gap={10} style={{ marginTop: "10px" }}>
      <TableToolbar title="Add template" />
      <TableOfTemplates />
    </Flex>
  );
};
